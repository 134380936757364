import { delay, call, put, takeEvery, takeLatest } from "redux-saga/effects";

import {
	GET_AVAILABLE_OPTIONS, 
	GET_AVAILABLE_OPTIONS_FAIL, 
	GET_DATE_OPTIONS, 
	GET_DATE_OPTIONS_FAIL, 
	START_CONSTRUCTION, 
	START_CONSTRUCTION_FAIL,
	RESET_TRADES,
	CREATE_DERIBIT_COMBO,
} from "./actionTypes";

import { 
	getAvailableOptions, 
	getDateOptions, 
	startButterflyConstruction, 
	resetTrades, 
	createDeribitCombo, 
} from "./services";

function* fetchAvailableOptions({ payload: { currency, type, option_type, day, side, userCommission,
	userSettlementCommission, userCapitalLimit } }) {
	try {
		if (currency && type && option_type && day) { 
			const response = yield call(getAvailableOptions, { currency, type, option_type, day, side, userCommission,
				userSettlementCommission, userCapitalLimit });
			if (response.status === 200) {
				yield put({
					type: GET_AVAILABLE_OPTIONS,
					payload: response.data,
				});
			} else {
				yield put({
					type: GET_AVAILABLE_OPTIONS_FAIL,
					payload: response.data,
				});
			}
		}
	} catch (err) {
		yield put({
			type: GET_AVAILABLE_OPTIONS_FAIL,
			payload: [],
		});
	}
}


function* fetchDateOptions({ payload: { currency, type, option_type } }) {
	try {
		if (currency && type && option_type) {
			const response = yield call(getDateOptions, { currency, type, option_type });
			if (response.status === 200) {
				yield put({
					type: GET_DATE_OPTIONS,
					payload: response.data,
				});
			} else {
				yield put({
					type: GET_DATE_OPTIONS_FAIL,
					payload: response.data,
				});
			}
		}
	} catch (err) {
		yield put({
			type: GET_DATE_OPTIONS_FAIL,
			payload: {},
		});
	}
}


function* fetchStartConstruction({ payload: {} }) {
	try {
		const response = yield call(startButterflyConstruction, {});
		if (response.status === 200) {
			yield put({
				type: START_CONSTRUCTION,
				payload: response.data,
			});
		} else {
			yield put({
				type: START_CONSTRUCTION_FAIL,
				payload: response.data,
			});
		}	
	} catch (err) {
		yield put({
			type: START_CONSTRUCTION_FAIL,
			payload: {},
		});
	}
}

function* fetchCreateDeribitCombo({ payload: {trades} }) {
	try {
		const response = yield call(createDeribitCombo, {trades});
		if (response.status === 200) {
			yield put({
				type: START_CONSTRUCTION,
				payload: response.data,
			});
		} else {
			yield put({
				type: START_CONSTRUCTION_FAIL,
				payload: response.data,
			});
		}	
	} catch (err) {
		yield put({
			type: START_CONSTRUCTION_FAIL,
			payload: {},
		});
	}
}


function* fetchResetTrades({ payload: {} }) {
	try {
		const response = yield call(resetTrades, {});
		if (response.status === 200) {
			yield put({
				type: RESET_TRADES,
				payload: response.data,
			});
		} else {
			yield put({
				type: RESET_TRADES,
				payload: response.data,
			});
		}	
	} catch (err) {
		yield put({
			type: RESET_TRADES,
			payload: {},
		});
	}
}

function* DeribitSaga() {
	yield takeEvery(GET_AVAILABLE_OPTIONS, fetchAvailableOptions);
	yield takeEvery(GET_DATE_OPTIONS, fetchDateOptions);
	yield takeEvery(START_CONSTRUCTION, fetchStartConstruction);
	yield takeEvery(RESET_TRADES, fetchResetTrades);
	yield takeEvery(CREATE_DERIBIT_COMBO, fetchCreateDeribitCombo);
}

export default DeribitSaga;
