import axios from "axios";
import { apiUrl, live } from "../../config";

export const getAvailableOptions = ({ currency, type, option_type, day, side, userCommission,
	userSettlementCommission, userCapitalLimit }) =>
	axios
		.get(`${apiUrl}/v1/get_option_data?live=${live}&currency=${currency}&type=${type}&option_type=${option_type}&day=${day}&side=${side}&userCommission=${userCommission}&userSettlementCommission=${userSettlementCommission}&userCapitalLimit=${userCapitalLimit}`)
		.then((response) => response)
		.catch((err) => err.response);

export const getDateOptions = ({ currency, type, option_type }) => 
	axios
		.get(`${apiUrl}/v1/get_active_market_dates?live=${live}&currency=${currency}&type=${type}&option_type=${option_type}`)
		.then((response) => response)
		.catch((err) => err.response);

export const startButterflyConstruction = () => 
	axios
		.get(`${apiUrl}/v1/start_butterfly_contruction?live=${live}`)
		.then((response) => response)
		.catch((err) => err.response);

export const resetTrades = () => 
	axios
		.get(`${apiUrl}/v1/reset_trades?live=${live}`)
		.then((response) => response)
		.catch((err) => err.response);

export const createDeribitCombo = ({ trades }) => 
	axios
		.post(`${apiUrl}/v1/create_butterfly_combo`, {
			trades: trades
		})
		.then((response) => response)
		.catch((err) => err.response);